<template>
  <div class="box">
    <!-- 循环订单信息 -->
    <div v-for="(item, index) in list" :key="index" class="p-lr-10 font-s-14 bgc p-b-10" @click="goOrderDetail(item)">
      <div class="p-tb-20 flex jcsb">
        <div>订单编号：{{ item.orderNo }}</div>
        <div class="font-c-red">{{ getStatus(item.status) }}</div>
      </div>
      <!-- 循环商品信息 -->
      <div class="flex a-i-fs jcsb m-t-5">
        <div class="flex">
          <div><img :src="getPic(item)" alt="" class="img" /></div>
          <div class="p-lr-10">
            {{ item.productName }}
            <p style="margin-top: 1rem;">下单时间:{{ item.createAt }} </p>
          </div>
        </div>
        <div class="price">
          <div v-if="item.extraPay" style="text-align: end;">
           
            <span style="font-size: 1rem;font-weight: 600;">{{ item.extraPay }}</span>
            <span style="font-size: 0.8rem;" >点</span>
          </div>
          <div v-if="item.payProductTotal" style="text-align: end; margin-top: 0.2rem; ">
            <span style="font-size: 1rem;font-weight: 600;">{{ item.payProductTotal }}</span>
            <span style="font-size: 0.8rem;">点</span>
          </div>
          <div class="t-a-r p-5 font-s-12" style="margin-top: 1rem;">共{{ item.productNum }}张</div>
        </div>
      </div>

      <!-- 收货地址信息 -->
      <!-- <div class="p-b-10 flex jce">
        <div style="border: 1px solid #aea9a9; ; border-radius: 1rem; padding: 0.2rem 0.5rem; color: #aea9a9;"
          @click.stop="deleteOrder">
          删除订单
        </div>
        <div
          style="border: 1px solid #fa2323; ; border-radius: 1rem; padding: 0.2rem 0.5rem; color: #fa2323;margin-left: 0.5rem; "
          @click.stop="goDetail">
          再次购买
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    deleteOrder(val) {
      this.$Toast('删除订单');

    },
    getPic(str) {
      if (str.orderDetail) {
        return JSON.parse(str.orderDetail).moviePicUrl
      } else {
        return str.pic
      }

    },
    goOrderDetail(val) {

      this.$router.push({
        path: "/OrderDetail",
        query: {
          orderId: val.id,
          qmmOrderId: val.tripartiteOrderNo,
          status: val.status,
          pic: val.pic,
          extraPay: val.extraPay,
          orderNo: val.orderNo
        },
      });
    },
    //0待支付 1待发货 2已发货 3已完成 4已关闭 5超时
    getStatus(val) {
      if (val == '0') {
        return '待支付'
      } else if (val == '1') {
        return '待发货'
      } else if (val == '2') {
        return '已发货'
      } else if (val == '3') {
        return '已完成'
      } else if (val == '4') {
        return '已取消'
      } else if (val == '5') {
        return '已超时'
      } else if (val == '6') {
        return '出票中'
      } else if (val == '7') {
        return '出票成功'
      } else if (val == '8') {
        return '出票失败'
      }
    }

  },
  mounted() {
    // console.log(this.list);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f5f5f5;

  .img {
    width: 5rem;
    height: 7rem;
    border-radius: 0.5rem;
  }

  .price {
    min-width: 3.5em;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .bgc {
    background-color: #fff;
    border-radius: 15px;
    margin: 10px 5px;
  }
}
</style>